@import url("https://fonts.cdnfonts.com/css/satoshi");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
button,
input,
optgroup,
select,
textarea {
	background-color: black;
}

body {
	background-color: #141021;
	margin: 0;
	font-family: "Satoshi", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.links {
	text-decoration: none;
	color: inherit;
}

.links:hover {
	text-decoration: none;
	color: inherit;
}

.map_pulse:before,
.map_pulse:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #9e77edc5;
	border-radius: 50%;
	z-index: -1;
	opacity: 0.7;
	left: 0;
}
.map_pulse:before {
	animation: mapPulse 2s ease-out infinite;
}
.map_pulse:after {
	animation: mapPulse 2s 1s ease-out infinite;
}

.gradient-text {
	background: linear-gradient(98deg, #6ddcff 0%, #7f60f9 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@keyframes mapPulse {
	100% {
		transform: scale(6);
		opacity: 0;
	}
}

.pulse {
	animation: pulse 5s infinite cubic-bezier(0.6, 0.5, 1, 1);
}
.pulse_b {
	animation: pulse 7s infinite cubic-bezier(0.6, 0.5, 1, 1);
	animation-delay: 2s;
}

.anim_pic img {
	animation: rotate 19s infinite linear;
}

@keyframes pulse {
	0% {
		transform: scale(1.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.8);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.scrolling {
	transform: translateX(100%);
	animation: scrolling 15s linear infinite;
}

@keyframes scrolling {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.move {
	-webkit-animation: move 30s linear infinite;
	-moz-animation: move 30s linear infinite;
	-o-animation: move 30s linear infinite;
	animation: move 30s 2s linear infinite;
}

@-webkit-keyframes move {
	from {
		-webkit-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@-moz-keyframes move {
	from {
		-moz-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@-o-keyframes move {
	from {
		-o-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@keyframes move {
	from {
		transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

/* anoter */
.move1 {
	-webkit-animation: move 30s linear infinite;
	-moz-animation: move 30s linear infinite;
	-o-animation: move 30s linear infinite;
	animation: move 70s linear infinite;
}
.move2 {
	-webkit-animation: move 30s linear infinite;
	-moz-animation: move 30s linear infinite;
	-o-animation: move 30s linear infinite;
	animation: move 50s linear infinite;
}

@-webkit-keyframes move1 {
	from {
		-webkit-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@-moz-keyframes move1 {
	from {
		-moz-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@-o-keyframes move1 {
	from {
		-o-transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}

@keyframes move1 {
	from {
		transform: rotate(0deg) translateX(20px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translateX(20px) rotate(-360deg);
	}
}
